<template>
  <Page isHeader>
    <div class="card">
      <!-- 卡信息 -->
      <div
        :style="{
          backgroundImage:
            'url(' +
            $baseImg +
            (cardInfo.coverUrl || 'photo/h5C/card/card_default.png') +
            ')',
        }"
        class="card-box content lk-relative"
      >
        <div class="card-info lk-flex lk-m-t-12">
          <van-image
            round
            width="36"
            height="36"
            :src="
              $baseImg + (cardInfo.cardLogo || 'photo/h5C/card/card_logo.png')
            "
          />
          <span class="info lk-font-16 van-ellipsis">
            {{ cardInfo.cardName }}
          </span>
        </div>
        <div class="card-status lk-absolute">
          {{ cardInfo.status | statusFmt }}
        </div>
        <p
          v-if="cardInfo.employer && cardInfo.employer !== ''"
          class="card-unit lk-absolute lk-font-14"
        >
          单位：
          <span>{{ cardInfo.employer }}</span>
        </p>
        <p class="card-num lk-absolute lk-font-14">
          卡号：
          <span>{{ NumberCut(cardInfo.cardNo || '') }}</span>
        </p>
      </div>
      <!-- 余额显示 -->
      <div class="balance-box lk-m-t-16 lk-p-l-12 lk-p-r-12">
        <p class="font-3 lk-font-12 lk-m-b-4">余额/元</p>
        <div class="lk-flex lk-row-between">
          <span class="font-1 lk-font-24 van-ellipsis">
            {{ cardInfo.totalAmount | amountFmt }}
          </span>
          <div
            :class="{ disabled: cardInfo.status !== 1 }"
            class="recharge-btn font-1"
            @click="handleToRecharge"
          >
            充值
          </div>
        </div>
      </div>
      <!-- <div class="lk-m-t-35 lk-p-b-20">
        <van-cell
          center
          is-link
          :value="menu.value"
          v-for="(menu, idx) in menuList"
          :key="idx"
          @click="handleClickMenu(menu)"
        >
          <template #icon>
            <i class="icon iconfont lk-font-24" :class="[menu.icon]" />
          </template>
          <template #title>
            <div class="lk-font-16 font-1 lk-m-l-16">{{ menu.name }}</div>
          </template>
        </van-cell>
      </div> -->
    </div>
    <!-- 菜单栏 -->
    <div class="menu-content">
      <div
        class="menu-item"
        v-for="(menu, idx) in menuList"
        :key="idx"
        @click="handleClickMenu(menu)"
      >
        <div class="menu-item-info">
          <div>
            <i
              style="font-size: 32px; color: #666666"
              class="icon iconfont"
              :class="[menu.icon]"
            />
          </div>
          <div style="font-size: 16px; color: #333; margin-top: 8px">
            {{ menu.name }}
          </div>
        </div>
      </div>
    </div>
    <!-- 提示 -->
    <div class="tips">
      开门柜消费，微信/支付宝授权开门后，优先扣余额，余额不足扣微信或支付宝
    </div>
    <Dialog
      :visible.sync="showDialog"
      :title="messageBox[dialogIdx].title"
      :confirmButtonText="messageBox[dialogIdx].confirmButtonText"
      :cancelButtonText="messageBox[dialogIdx].cancelButtonText"
      :content="messageBox[dialogIdx].message"
      @confirm="confirmDialog"
    />
  </Page>
</template>

<script>
import { NumberCut } from '@/utils/format';
import { updateCardStatusApi, checkPhoneOrderApi } from '@/api/card';
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'User',
  components: {},
  data() {
    return {
      NumberCut,
      showDialog: false,
      showBindDialog: false,
      bingMessage: '该手机存在进行中订单，请先等待处理后再换绑',
      dialogIdx: 1,
    };
  },
  beforeRouteEnter(to, from, next) {
    // ...
    next((vm) => {
      let { cardNo, serialNo } = to.query;
      if (cardNo && serialNo) {
        console.log(' payload!!!!==>', to.query);
        vm.getCardInfoAction({ cardNo, serialNo, isList: true });
      } else {
        console.log('no payload!!!!');
        vm.getCardInfoAction();
      }
    });
  },
  computed: {
    ...mapState('card', ['cardInfo']),
    ...mapGetters('card', ['menuList', 'messageBox']),
  },
  methods: {
    ...mapActions('card', ['getCardInfoAction']),
    ...mapActions('app', ['resetToLogin']),
    handleClickMenu(n) {
      console.log(n);

      if (typeof n.urlName === 'number') {
        let idx = n.urlName;
        this.dialogIdx = n.urlName;
        console.log(idx);
        if (idx == 9) {
          console.log('打电话');
          window.location.href = `tel://${this.cardInfo.servicePhone}`;
          return false;
        }

        this.showDialog = true;
      } else {
        this.$router.push({
          name: n.urlName,
          query: n.query,
        });
      }
    },
    async confirmDialog() {
      if (this.dialogIdx == 5) {
        this.resetToLogin();
      } else if (this.dialogIdx == 6) {
        // ToDo：查询手机号是否存在 未支付订单/支付中订单

        try {
          await checkPhoneOrderApi({
            cardNo: this.cardInfo.cardNo,
            phone: this.cardInfo.phone,
          });
          this.showDialog = false;
          this.$router.push({
            name: 'ChangePhone',
          });
        } catch (error) {
          // this.bingMessage = error.message;
          // this.showBindDialog = true;
          return false;
        }
      } else {
        this.updateStatusAction(this.dialogIdx);
      }
    },
    async updateStatusAction(idx) {
      console.log('idx==>', idx);
      // idx 1：正常（取消补卡 2，取消挂失）3：挂失 4：补卡
      try {
        const { code } = await updateCardStatusApi({
          status: idx == 2 ? 1 : idx, // idx = 2的时候是取消补卡状态，但是后端都需要传1
          cardNo: this.cardInfo.cardNo,
          serialNo: this.cardInfo.serialNo,
        });
        if (code === 200) {
          let text = [
            '',
            '取消挂失成功',
            '取消补卡成功',
            '挂失成功',
            '申请补卡成功',
          ];
          this.$toast(text[idx]);
          this.getCardInfoAction();
        }
      } catch (error) {
        console.log(error);
      }
    },
    handleToRecharge() {
      // if (!this.cardInfo.rechargeEnable) {
      //   this.$toast("当前储值卡已关闭充值")
      //   return false
      // }
      if (this.cardInfo.status !== 1) {
        this.$toast('当前储值卡处于非正常状态');
        return false;
      }
      this.$router.push({
        name: 'Recharge',
      });
    },
    confirmBingDialog() {
      console.log('跳转换绑页面');
    },
    // handleClickChangePhone() {
    //   this.$dialog
    //     .confirm({
    //       title: '确认切换手机号?',
    //       message: '切换手机号需要重新登录',
    //       confirmButtonColor: '#F8B500',
    //     })
    //     .then(() => {
    //       this.resetToLogin();
    //     })
    //     .catch(() => {});
    // },
  },
};
</script>

<style lang="scss" scoped>
.card-box {
  width: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 210px;
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  .card-info {
    margin: 12px 0;
    padding: 12px 0;
    .info {
      flex: 1;
      display: inline-block;
      margin-left: 12px;
      font-weight: 500;
      color: #fff;
    }
  }
  .card-status {
    top: 10px;
    right: 0;
    background-color: #f56c6c;
    color: #fff;
    font-size: 12px;
    border-radius: 10px 0 0 10px;
    height: 20px;
    line-height: 20px;
    padding: 0 10px;
    text-align: center;
  }
  .card-num {
    left: 12px;
    bottom: 24px;
    color: #fff;
  }
  .card-unit {
    left: 12px;
    bottom: 48px;
    color: #fff;
  }
}
.balance-box {
  .recharge-btn {
    width: 64px;
    height: 24px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    background-color: #f8b500;
    border-radius: 12px;
  }
  .disabled {
    opacity: 0.5;
  }
}
.bind-other-card {
  width: 100%;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #f8b500;
  line-height: 40px;
  text-align: center;
  box-sizing: border-box;
}

.card {
  background-color: #fff;
  padding: 12px;
}

.menu-content {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
  background-color: #fff;
  padding: 0 12px;
}
.menu-item {
  width: 25%;
  // margin-bottom: 20px;
  margin: 12px 0;
  .menu-item-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.tips {
  margin-top: 8px;
  margin-left: 12px;
  margin-right: 12px;
  font-size: 14px;
  color: #999;
  line-height: 20px;
}
</style>
